<template>
    <div>
        <b-card>  
            <div class="profile-container">
                <div class="nid-container">
                    <div class="no-scroll-part">
                        <div style="display: flex; gap: 8px;">
                            <p style="color: rgb(75 85 99); font-size: 1.5rem; font-weight: 500;">Nid / Passport: </p>
                            <p class="title capital-letter">{{ row.id_info.id_type }}</p>
                        </div>

                        <img @click="showImage(row.id_info.front_part_image)" v-if="row.id_info.front_part_image" class="nid-passport-image" :src="host + row.id_info.front_part_image" alt="front_part_image">
                        <img @click="showImage(row.id_info.back_part_image)" v-if="row.id_info.back_part_image" class="nid-passport-image" :src="host + row.id_info.back_part_image" alt="back_part_image">

                        <div v-if="feedbackBox">
                            <textarea v-if="feedbacks.nid_info" id="w3review" name="w3review" rows="3" style="width: 100%; outline: none; border-color: #0075ff; border-radius: 0.25rem; padding: 8px;" placeholder="Write feedback..." v-model="feedbacks.nid_info.feedback"></textarea>

                            <div v-if="feedbacks.nid_info" style="width: 100%; display: flex; justify-content: space-between;">
                                <div v-if="feedbacks.nid_info.resolved == false">
                                    <button @click="feedbackResolved('nid_info')">Resolved</button>
                                </div>
                                <div v-else>
                                    <p style="color: #0075ff;">Feedback Resolved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="info-container">
                    <div class="button-container">
                        <div class="button-half">
                            <span> 
                                <span @click="updateStatustoChecked()" class="view-log-button">Checked</span>
                            </span>
                            <span>
                                <span @click="updateStatustoFurtherCorrectionDue()" class="view-log-button">Further Correction Due</span>
                            </span>
                        </div>
                        <div class="button-half">
                            <span> 
                                <router-link :to="{ path: '/admin/report/activity-log/' + row.personal_info.profile_id }">
                                    <span class="view-log-button">Activity Log</span>
                                </router-link>
                            </span>
                            <span>
                                <router-link :to="{ path: '/admin/report/summary/' + row.personal_info.profile_id }">
                                    <span class="view-log-button">View Summary</span>
                                </router-link>
                            </span>
                        </div>
                    </div>

                    <div class="top-gap">
                        <div class="title-row">
                            <p class="title">Personal Information</p>
                            <b-button class="title-edit" @click="editPersonal(row)" v-b-modal.modal-xl>
                                Edit
                            </b-button>
                        </div>
                        <div class="user-container">
                            <div class="user-width">
                                <!-- <div class="table-row">
                                    <p class="title-position">User:</p>
                                    <p v-if="row.personal_info.user">{{ row.personal_info.user }}</p>
                                </div> -->
                                <div class="table-row">
                                    <p class="title-position">Profile Id:</p>
                                    <p v-if="row.personal_info.profile_id">{{ row.personal_info.profile_id }}</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Name:</p>
                                    <p v-if="row.user_object.first_name">{{ row.user_object.first_name }} {{ row.user_object.last_name }}</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Date of Birth:</p>
                                    <p v-if="row.personal_info.date_of_birth">{{ row.personal_info.date_of_birth }}</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Gender:</p>
                                    <p v-if="row.personal_info.gender" class="capital-letter">{{ row.personal_info.gender }}</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Religion:</p>
                                    <p v-if="row.personal_info.religion" class="capital-letter">{{ row.personal_info.religion }}</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Height:</p>
                                    <p v-if="row.personal_info.height_ft && row.personal_info.height_inch">{{ row.personal_info.height_ft }}.{{ row.personal_info.height_inch }}</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Marital Status:</p>
                                    <p v-if="row.personal_info.marital_status">{{ row.personal_info.marital_status }}</p>
                                </div>
                                <div class="table-row" v-if="row.personal_info.marital_status !== 'never_married'">
                                    <p class="title-position">Number of Children:</p>
                                    <p v-if="row.personal_info.marital_status !== 'never_married' && row.personal_info.children">{{ row.personal_info.children }}</p>
                                </div>
                            </div>
                            <div class="user-width">
                                <!-- <div class="table-row">
                                    <p class="title-position">Blood Group:</p>
                                    <p v-if="row.personal_info.blood_group">{{ row.personal_info.blood_group }}</p>
                                </div> -->
                                <div class="table-row">
                                    <p class="title-position">Citizenship:</p>
                                    <p v-if="row.personal_info.citizenship">{{ row.personal_info.citizenship.name }}</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Phone No:</p>
                                    <p v-if="row.personal_info.phone_number">{{ row.personal_info.phone_number }}</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Email:</p>
                                    <p v-if="row.user_object.email">{{ row.user_object.email }}</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Age:</p>
                                    <p v-if="row.personal_info.age">{{ row.personal_info.age }}</p>
                                </div>
                                <!-- <div class="table-row">
                                    <p class="title-position">Hometown:</p>
                                    <p v-if="row.personal_info.hometown">{{ row.personal_info.hometown.name }}</p>
                                </div> -->
                                <div class="table-row"> 
                                    <p class="title-position">Profile Status:</p>
                                    <span v-if="row.personal_info.profile_status == 'active'"><b-badge pill variant="light-success">{{row.personal_info.profile_status}}</b-badge></span>
                                    <span v-else> <b-badge pill variant="light-danger">{{row.personal_info.profile_status}}</b-badge></span>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Premium Status:</p>
                                    <span v-if="row.personal_info.premium_status === 'premium'"><b-badge pill variant="light-success">Premium</b-badge></span>
                                    <span v-else> <b-badge pill variant="light-info">Regular</b-badge></span>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Phone Verified:</p>
                                    <span v-if="row.personal_info.phone_verified === true"><b-badge pill variant="light-success">Yes</b-badge></span>
                                    <span v-else> <b-badge pill variant="light-danger">No</b-badge></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="top-gap">
                        <div class="title-row">
                            <p class="title">Address</p>
                            <b-button class="title-edit" @click="editAddress(row)" v-b-modal.modal-xl>
                                Edit
                            </b-button>
                        </div>
                        <div class="user-container">
                            <div class="user-width">
                                <div style="display: flex; gap: 12px;">
                                    <!-- <div v-if="feedbackBox" style="display: flex; height: 100%; align-items: start; margin-top: 4px;">
                                        <input type="checkbox" id="myCheck" v-model="value">
                                    </div> -->
                                    <div>
                                        <p class="address-title">Present Address:</p>
                                    </div>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Area:</p>
                                    <p v-if="row.present_address.area">{{ row.present_address.area.name }}</p>
                                    <p v-else>N / A</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">District:</p>
                                    <p v-if="row.present_address.district">{{ row.present_address.district.name }}</p>
                                    <p v-else>N / A</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Country:</p>
                                    <p v-if="row.present_address.country">{{ row.present_address.country.name }}</p>
                                </div>
                            </div>
                            <div class="user-width">
                                <div style="display: flex; gap: 12px;">
                                    <!-- <div v-if="feedbackBox" style="display: flex; height: 100%; align-items: start; margin-top: 4px;">
                                        <input type="checkbox" id="myCheck" v-model="value">
                                    </div> -->
                                    <div>
                                        <p class="address-title">Hometown:</p>
                                    </div>
                                </div>
                                <!-- <div class="table-row">
                                    <p class="title-position">Area:</p>
                                    <p v-if="row.permanent_address.area">{{ row.permanent_address.area.name }}</p>
                                    <p v-else>N / A</p>
                                </div> -->
                                <div class="table-row">
                                    <p class="title-position">District:</p>
                                    <p v-if="row.permanent_address.district">{{ row.permanent_address.district.name }}</p>
                                    <p v-else>N / A</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Country:</p>
                                    <p v-if="row.permanent_address.country">{{ row.permanent_address.country.name }}</p>
                                </div>
                            </div>
                        </div>
                        <!-- <div v-if="feedbackBox">
                            <textarea id="w3review" name="w3review" rows="3" style="width: 100%; outline: none; border-color: #0075ff; border-radius: 0.25rem; padding: 8px;" placeholder="Write feedback..."></textarea>
                        </div> -->
                    </div>

                    <!-- Educational Information -->
                    <div class="top-gap">
                        <div class="title-row">
                            <p class="title">Educational Information</p>
                            <b-button class="title-edit" @click="editEducational(row.educational_info)" v-b-modal.modal-xl>
                                Edit
                            </b-button>
                        </div>
                        <div class="education-container">
                            <div v-for="(education, index) in row.educational_info" :key="index">
                                <p class="address-title">Educational Information: {{ row.educational_info.indexOf(education) + 1 }}</p>
                                <div class="table-row">
                                    <p class="title-position">Degree:</p>
                                    <p v-if="education.degree">{{ education.degree.name }}</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Subject:</p>
                                    <p v-if="education.subject">{{ education.subject }}</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Passing Year:</p>
                                    <p>{{ education.passing_year }}</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Institution:</p>
                                    <p v-if="education.institution_name">{{ education.institution_name }}</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Country:</p>
                                    <p v-if="education.country">{{ education.country.name }}</p>
                                </div>
                            </div>
                        </div>
                        <div v-if="feedbackBox">
                            <textarea v-if="feedbacks.educational_info" id="w3review" name="w3review" rows="3" style="width: 100%; outline: none; border-color: #0075ff; border-radius: 0.25rem; padding: 8px;" placeholder="Write feedback..." v-model="feedbacks.educational_info.feedback"></textarea>

                            <div v-if="feedbacks.educational_info" style="width: 100%; display: flex; justify-content: space-between;">
                                <div v-if="feedbacks.educational_info.resolved == false">
                                    <button @click="feedbackResolved('educational_info')">Resolved</button>
                                </div>
                                <div v-else>
                                    <p style="color: #0075ff;">Feedback Resolved.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Professional Information -->
                    <div class="top-gap">
                        <div class="title-row">
                            <p class="title">Professional Information</p>
                            <b-button class="title-edit" @click="editProfessional(row.professional_info)" v-b-modal.modal-xl>
                                Edit
                            </b-button>
                        </div>
                        <div class="education-container">
                            <div v-for="(profession, index) in row.professional_info" :key="index">
                                <p class="address-title">Professional Information: {{ row.professional_info.indexOf(profession) + 1 }}</p>
                                <div class="table-row">
                                    <p class="title-position">Industry:</p>
                                    <p v-if="profession.industry_category">{{ profession.industry_category.name }}</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Designation:</p>
                                    <p v-if="profession.designation">{{ profession.designation.name }}</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Organization:</p>
                                    <p v-if="profession.organization_name">{{ profession.organization_name }}</p>
                                </div>
                            </div>
                        </div>
                        <div v-if="feedbackBox">
                            <textarea v-if="feedbacks.professional_info" id="w3review" name="w3review" rows="3" style="width: 100%; outline: none; border-color: #0075ff; border-radius: 0.25rem; padding: 8px;" placeholder="Write feedback..." v-model="feedbacks.professional_info.feedback"></textarea>

                            <div v-if="feedbacks.professional_info" style="width: 100%; display: flex; justify-content: space-between;">
                                <div v-if="feedbacks.professional_info.resolved == false">
                                    <button @click="feedbackResolved('professional_info')">Resolved</button>
                                </div>
                                <div v-else>
                                    <p style="color: #0075ff;">Feedback Resolved.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Family Information -->
                    <div class="top-gap">
                        <div class="title-row">
                            <p class="title">Family Information</p>
                            <b-button class="title-edit" @click="editFamily(row.family_info)" v-b-modal.modal-xl>
                                Edit
                            </b-button>
                        </div>
                        <div class="user-container">
                            <div class="user-width">
                                <div class="table-row">
                                    <!-- <div v-if="feedbackBox" style="display: flex; height: 100%; align-items: start; margin-top: 4px;">
                                        <input type="checkbox" id="myCheck" v-model="value">
                                    </div> -->
                                    <p class="title-position">Father's Profession:</p>
                                    <p v-if="row.family_info.father_profession">{{ row.family_info.father_profession.name }}</p>
                                </div>
                                <div class="table-row">
                                    <!-- <div v-if="feedbackBox" style="display: flex; height: 100%; align-items: start; margin-top: 4px;">
                                        <input type="checkbox" id="myCheck" v-model="value">
                                    </div> -->
                                    <p class="title-position">Father's Hometown:</p>
                                    <p v-if="row.family_info.father_hometown">{{ row.family_info.father_hometown.name }}</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Mother's Profession:</p>
                                    <p v-if="row.family_info.mother_profession">{{ row.family_info.mother_profession.name }}</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Mother's Hometown:</p>
                                    <p v-if="row.family_info.mother_hometown">{{ row.family_info.mother_hometown.name }}</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Number of Brothers:</p>
                                    <p v-if="row.family_info.number_of_brothers">{{ row.family_info.number_of_brothers }}</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position">Number of Sisters:</p>
                                    <p v-if="row.family_info.number_of_sisters">{{ row.family_info.number_of_sisters }}</p>
                                </div>
                            </div>
                            <div class="user-width">
                                <div class="table-row">
                                    <p class="title-position-small">About Myself:</p>
                                    <p v-if="row.family_info.my_bio">{{ row.family_info.my_bio }}</p>
                                </div>
                                <div class="table-row">
                                    <p class="title-position-small">About Family:</p>
                                    <p v-if="row.family_info.family_details">{{ row.family_info.family_details }}</p>
                                </div>
                            </div>
                        </div>
                        <div v-if="feedbackBox">
                            <textarea v-if="feedbacks.family_info" id="w3review" name="w3review" rows="3" style="width: 100%; outline: none; border-color: #0075ff; border-radius: 0.25rem; padding: 8px;" placeholder="Write feedback..." v-model="feedbacks.family_info.feedback"></textarea>

                            <div v-if="feedbacks.family_info" style="width: 100%; display: flex; justify-content: space-between;">
                                <div v-if="feedbacks.family_info.resolved == false">
                                    <button @click="feedbackResolved('family_info')">Resolved</button>
                                </div>
                                <div v-else>
                                    <p style="color: #0075ff;">Feedback Resolved.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Hobbies -->
                    <div class="top-gap">
                        <div class="title-row">
                            <p class="title">Hobbies</p>
                        </div>
                        <div style="display: grid; grid-template-columns: repeat(3, minmax(0, 1fr)); gap: 20px;">
                            <div v-for="(hobby, index) in row.personal_info.hobby" :key="index">
                                <p style="background-color: #a41c1c; color: white; text-align: center; padding: 2px; border-radius: 0.25rem;">{{ hobby.name }}</p>
                            </div>
                        </div>
                    </div>

                    <!-- Picture -->
                    <div class="top-gap">
                        <div class="title-row">
                            <p class="title">Picture</p>
                        </div>
                        <div class="picture-display">
                            <div v-for="(img, index) in row.picture_list.images" :key="index">
                                <div v-if="img" class="image-container">
                                    <img @click="showImage(img)" class="image-size object-contain" :src="host + img" alt="">
                                    <div class="button-position">
                                        <img @click="deleteImage(row.picture_list.images.indexOf(img) + 1)" v-b-modal.modal-1 class="cross-button" src="../../../../assets/images/icons/close.png" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="feedbackBox">
                            <textarea v-if="feedbacks.profile_pictures" id="w3review" name="w3review" rows="3" style="width: 100%; outline: none; border-color: #0075ff; border-radius: 0.25rem; padding: 8px;" placeholder="Write feedback..." v-model="feedbacks.profile_pictures.feedback"></textarea>

                            <div v-if="feedbacks.profile_pictures" style="width: 100%; display: flex; justify-content: space-between;">
                                <div v-if="feedbacks.profile_pictures.resolved == false">
                                    <button @click="feedbackResolved('profile_pictures')">Resolved</button>
                                </div>
                                <div v-else>
                                    <p style="color: #0075ff;">Feedback Resolved.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-1" v-if="feedbackBox" style="margin-top: 16px; width: 100%; display: flex; justify-content: center;">
                        <b-button class="title-edit" style="padding: 10px;" @click="submitAllFeedbacks()">Submit All Feedback</b-button>
                    </div>
                </div>

                <div v-if="showImageDialog" :class="{ 'search-overlay' : showImageDialog }">
                    <div class="modal-container">
                        <div>
                            <div class="cancel-button-position">
                                <img @click="cancelDialog()" class="cancel-image-button bg-white shadow" src="../../../../assets/images/icons/close.png" alt="">
                            </div>
                            <div class="flex justify-center mt-2">
                                <div v-if="display_image">
                                    <img class="img-size" :src="host + display_image" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-card> 

        <!-- Update Personal Info -->
        <b-modal
            v-if="updatePersonalMode"
            id="modal-xl"
            size="lg"
            centered
            title="Update Personal Information"
            ok-title="Submit"
            cancel-variant="outline-secondary"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
            <form
                ref="form"
                @submit.stop.prevent="handleSubmit"
            >
                <!-- {{ personal }} -->
                <div class="education-container">
                    <b-form-group
                        :state="nameState"
                        label="First Name"
                        label-for="name-input"
                    >
                        <b-form-input
                            id="name-input"
                            v-model="personal.first_name"
                            :state="nameState"
                        />
                    </b-form-group>
                    <b-form-group
                        :state="nameState"
                        label="Last Name"
                        label-for="name-input"
                    >
                        <b-form-input
                            id="name-input"
                            v-model="personal.last_name"
                            :state="nameState"
                        />
                    </b-form-group>
                </div>
                <div class="education-container">
                    <b-form-group
                        :state="nameState"
                        label="Date of Birth"
                        label-for="name-input"
                    >
                        <b-form-datepicker
                            id="example-datepicker"
                            v-model="personal.date_of_birth"
                        />
                    </b-form-group>
                    <b-form-group
                        :state="nameState"
                        label="Gender"
                        label-for="name-input"
                    >
                        <v-select
                            id="fh-status"
                            v-model="personal.gender"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="gender_choice"
                            :reduce="name=>name.value"
                            label="name"
                        />
                    </b-form-group>
                </div>
                <div class="education-container">
                    <b-form-group
                        :state="nameState"
                        label="Religion"
                        label-for="name-input" 
                    >
                        <v-select
                            id="fh-status"
                            v-model="personal.religion"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="religion_choice"
                            :reduce="name=>name.value"
                            label="name"
                        />
                    </b-form-group>
                    <b-form-group
                        :state="nameState"
                        label="Marital Status"
                        label-for="name-input"
                    >
                        <v-select
                            id="fh-status"
                            v-model="personal.marital_status"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="marital_status_choice "
                            :reduce="name=>name.value" 
                            label="name"
                        />
                    </b-form-group>
                </div>
                <div class="education-container">
                    <b-form-group
                        :state="nameState"
                        label="Height (Feet)"
                        label-for="name-input" 
                    >
                        <b-form-input
                            id="name-input"
                            v-model="personal.height_ft"
                            :state="nameState"
                        />
                    </b-form-group>
                    <b-form-group
                        :state="nameState"
                        label="Height (Inches)"
                        label-for="name-input"
                    >
                        <b-form-input
                            id="name-input"
                            v-model="personal.height_inch"
                            :state="nameState"
                        />
                    </b-form-group>
                </div>
                <div class="education-container">
                    <b-form-group
                        :state="nameState"
                        label="citizenship"
                        label-for="name-input"
                    >
                        <v-select
                            id="fh-status"
                            v-model="personal.citizenship"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="country"
                            :reduce="name=>name.id"
                            label="name"
                        />
                    </b-form-group>
                    <b-form-group
                        :state="nameState"
                        label="Phone Number"
                        label-for="name-input"
                    >
                        <b-form-input
                            id="name-input"
                            v-model="personal.phone_number"
                            :state="nameState"
                        />
                    </b-form-group>
                </div>
                <div class="education-container">
                    <b-form-group
                        :state="nameState"
                        label="Email"
                        label-for="name-input"
                    >
                        <b-form-input
                            id="name-input"
                            v-model="personal.email"
                            :state="nameState"
                        />
                    </b-form-group>
                    <!-- <b-form-group
                        :state="nameState"
                        label="Profile Status"
                        label-for="fh-status"
                    >
                        <v-select
                            id="fh-status"
                            v-model="personal.profile_status"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="statuses"
                            :reduce="name=>name.value"
                            label="name"
                        />
                    </b-form-group> -->
                </div> 

                <!-- <div class="education-container">
                    <b-form-group
                        :state="nameState"
                        label="Premium Status"
                        label-for="name-input"
                    >
                        <v-select
                            id="fh-status"
                            v-model="personal.premium_status"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="prem_statuses"
                            :reduce="name=>name.value"
                            label="name"
                        />
                    </b-form-group>
                    <b-form-group
                        :state="nameState"
                        label="Phone Verified"
                        label-for="name-input"
                    >
                        <v-select
                            id="fh-status"
                            v-model="personal.phone_verified"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="verified_statuses"
                            :reduce="name=>name.value"
                            label="name"
                        />
                    </b-form-group>
                </div> -->

                <!-- <div class="education-container"> 
                    <b-form-group
                        :state="nameState"
                        label="Hometown"
                        label-for="name-input"
                    >
                        <v-select
                            id="fh-status"
                            v-model="personal.hometown"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="district"
                            :reduce="name=>name.id"
                            label="name"
                        />
                    </b-form-group> 
                    <b-form-group
                        :state="nameState"
                        label="Blood Group"
                        label-for="name-input"
                    >
                        <b-form-input
                            id="name-input"
                            v-model="personal.blood_group"
                            :state="nameState"
                        />
                    </b-form-group>
                </div> -->
            </form>
        </b-modal>

        <!-- Update Educational Info -->
        <b-modal
            v-if="updateEducationalMode"
            id="modal-xl"
            size="lg"
            centered
            ok-title="Close"
            title="Update Educational Information"
            ok-only
        >
            <form
                ref="form"
            >
                <div v-for="(edu, index) in educational_info" :key="index">
                    <div class="bottom-gap">
                        <p class="address-title">Educational Information: {{ educational_info.indexOf(edu) + 1 }}</p>
                        <div class="user-container">
                            <div class="user-width">
                                <b-form-group
                                    :state="nameState"
                                    label="Degree"
                                    label-for="name-input"
                                >
                                    <v-select
                                        id="fh-status"
                                        v-model="edu.degree"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        disabled="true"
                                        :reduce="name=>name.id"
                                        label="name"
                                    />
                                </b-form-group>
                            </div>
                            <div class="user-width">
                                <b-form-group
                                    :state="nameState"
                                    label="Subject"
                                    label-for="name-input"
                                >
                                    <b-form-input
                                        id="name-input"
                                        v-model="edu.subject"
                                        :state="nameState"
                                    />
                                </b-form-group>
                            </div>
                        </div>
                        <div class="user-container">
                            <div class="user-width">
                                <b-form-group
                                    :state="nameState"
                                    label="Passing Year"
                                    label-for="name-input"
                                >
                                    <b-form-input
                                        id="name-input"
                                        v-model="edu.passing_year"
                                        :state="nameState"
                                    />
                                </b-form-group>
                            </div>
                            <div class="user-width">
                                <b-form-group
                                    :state="nameState"
                                    label="Institution"
                                    label-for="name-input"
                                >
                                    <b-form-input
                                        id="name-input"
                                        v-model="edu.institution_name"
                                        :state="nameState"
                                    />
                                </b-form-group>
                            </div>
                        </div>
                        <div class="user-container">
                            <div class="user-width">
                                <b-form-group
                                    :state="nameState"
                                    label="Country"
                                    label-for="name-input"
                                >
                                    <v-select
                                        id="fh-status"
                                        v-model="edu.country"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="country"
                                        :reduce="name=>name.id"
                                        label="name"
                                    />
                                </b-form-group>
                            </div>
                            <div class="user-width top-gap">
                                <b-button
                                    id="toggle-btn"
                                    v-b-modal.modal-prevent-closing
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    variant="outline-primary"
                                    @click="editEducation(edu)"
                                >
                                    <feather-icon
                                        icon="EditIcon"
                                        class="mr-50"
                                    />
                                    <span class="align-middle">Edit</span>
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>

        <!-- Update Professional Info -->
        <b-modal
            v-if="updateProfessionalMode"
            id="modal-xl"
            size="lg"
            centered
            ok-title="Close"
            title="Update Professional Information"
            ok-only
        >
            <form
                ref="form"
            >
                <div v-for="(prof, index) in prof_info" :key="index">
                    <div class="bottom-gap">
                        <p class="address-title">Professional Information: {{ prof_info.indexOf(prof) + 1 }}</p>
                        <div class="user-container">
                            <div class="user-width">
                                <b-form-group
                                    :state="nameState"
                                    label="Industry"
                                    label-for="name-input"
                                >
                                    <v-select
                                        id="fh-status"
                                        v-model="prof.industry_category.name"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        disabled="true"
                                    />
                                </b-form-group>
                            </div>
                            <div class="user-width">
                                <b-form-group
                                    :state="nameState"
                                    label="Designation"
                                    label-for="name-input"
                                >
                                    <v-select
                                        id="fh-status"
                                        v-model="prof.designation.name"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        disabled="true"
                                    />
                                </b-form-group>
                            </div>
                        </div>
                        <div class="user-container">
                            <div class="user-width">
                                <b-form-group
                                    :state="nameState"
                                    label="Organization"
                                    label-for="name-input"
                                >
                                    <b-form-input
                                        id="name-input"
                                        v-model="prof.organization_name"
                                        :state="nameState"
                                    />
                                </b-form-group>
                            </div>
                            <div class="user-width top-gap">
                                <b-button
                                    id="toggle-btn"
                                    v-b-modal.modal-prevent-closing
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    variant="outline-primary"
                                    @click="editProfession(prof)"
                                >
                                    <feather-icon
                                        icon="EditIcon"
                                        class="mr-50"
                                    />
                                    <span class="align-middle">Edit</span>
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>

        <!-- Update Family Info -->
        <b-modal
            v-if="updateFamilyMode"
            id="modal-xl"
            size="lg"
            centered
            ok-title="Close"
            title="Update Family Information"
            ok-only
        >
            <form
                ref="form"
            >
                <b-form-group
                    :state="nameState"
                    label="About Myself"
                    label-for="name-input"
                >
                    <b-form-textarea
                        id="name-input"
                        v-model="family_info.my_bio"
                        :state="nameState"
                    />
                </b-form-group>
                <b-form-group
                    :state="nameState"
                    label="About Family"
                    label-for="name-input"
                >
                    <b-form-textarea
                        id="name-input"
                        v-model="family_info.family_details"
                        :state="nameState"
                    />
                </b-form-group>
                <b-button
                    id="toggle-btn"
                    v-b-modal.modal-prevent-closing
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="editFamilyInformation()"
                >
                    <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Edit</span>
                </b-button>
            </form>
        </b-modal>

        <!-- Update Address Info -->
        <b-modal
            v-if="updateAddressMode"
            id="modal-xl"
            size="lg"
            centered
            ok-title="Close"
            title="Update Address Information"
            ok-only
        >
            <form
                ref="form"
            >
                <div class="bottom-gap">
                    <p class="address-title">Present Address</p>
                    <div class="user-container">
                        <div class="user-width">
                            <b-form-group
                                :state="nameState"
                                label="Area"
                                label-for="name-input"
                            >
                                <v-select
                                    id="fh-status"
                                    v-model="present_address.area"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="area"
                                    :reduce="name=>name.id"
                                    label="name"
                                />
                            </b-form-group>
                        </div>
                        <div class="user-width">
                            <b-form-group
                                :state="nameState"
                                label="District"
                                label-for="name-input"
                            >
                                <v-select
                                    id="fh-status"
                                    v-model="present_address.district"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="district"
                                    :reduce="name=>name.id"
                                    label="name"
                                />
                            </b-form-group>
                        </div>
                    </div>
                    <div class="user-container">
                        <div class="user-width">
                            <b-form-group
                                :state="nameState"
                                label="Country"
                                label-for="name-input"
                            >
                                <v-select
                                    id="fh-status"
                                    v-model="present_address.country"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="country"
                                    :reduce="name=>name.id"
                                    label="name"
                                />
                            </b-form-group>
                        </div>
                        <div class="user-width top-gap">
                            <b-button
                                id="toggle-btn"
                                v-b-modal.modal-prevent-closing
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                @click="editPresentAddress()"
                            >
                                <feather-icon
                                    icon="EditIcon"
                                    class="mr-50"
                                />
                                <span class="align-middle">Edit</span>
                            </b-button>
                        </div>
                    </div>
                </div>
                <div class="bottom-gap" style="margin-bottom: 70px;">
                    <p class="address-title">Hometown</p>
                    <div class="user-container">
                        <div class="user-width">
                            <b-form-group
                                :state="nameState"
                                label="District"
                                label-for="name-input"
                            >
                                <v-select
                                    id="fh-status"
                                    v-model="permanent_address.district"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="district"
                                    :reduce="name=>name.id"
                                    label="name"
                                />
                            </b-form-group>
                        </div>
                        <div class="user-width">
                            <!-- <b-form-group
                                :state="nameState"
                                label="Area"
                                label-for="name-input"
                            >
                                <v-select
                                    id="fh-status"
                                    v-model="permanent_address.area"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="area"
                                    :reduce="name=>name.id"
                                    label="name"
                                />
                            </b-form-group> -->
                        </div>
                    </div>
                    <div class="user-container">
                        <div class="user-width">
                            <b-form-group
                                :state="nameState"
                                label="Country"
                                label-for="name-input"
                            >
                                <v-select
                                    id="fh-status"
                                    v-model="permanent_address.country"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="country"
                                    :reduce="name=>name.id"
                                    label="name"
                                />
                            </b-form-group>
                        </div>
                        <div class="user-width top-gap">
                            <b-button
                                id="toggle-btn"
                                v-b-modal.modal-prevent-closing
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                @click="editPermanentAddress()"
                            >
                                <feather-icon
                                    icon="EditIcon"
                                    class="mr-50"
                                />
                                <span class="align-middle">Edit</span>
                            </b-button>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>

        <!-- Update Status Modal  -->
        <b-modal
            v-if="updateStatusMode"
            id="modal-prevent-closing-1"
            ref="my-modal"
            title="Update Account Status"
            centered
            ok-title="Submit"
            cancel-variant="outline-secondary"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleStatus"
        >
            <form
                ref="form"
                @submit.stop.prevent=""
            >
                <b-form-group
                    :state="nameState"
                    label="Page"
                    label-for="name-input"
                >
                    <v-select
                        id="fh-status"
                        v-model="page"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="pages"
                        :reduce="name=>name.value"
                        label="name"
                        placeholder="Select a Page"
                    />
                </b-form-group>
                <b-form-group
                    :state="nameState"
                    label="Feedback"
                    label-for="name-input"
                >
                    <b-form-textarea
                        id="name-input" 
                        v-model="feedback"
                        :state="nameState"
                        rows="4"
                        resize
                    />
                </b-form-group>
            </form>
        </b-modal>

        <!-- Image Delete Confirmation  -->
        <b-modal id="modal-1" title="Delete Image" @ok="deleteModalImage">
            <p class="mt-1">Are you sure you want to delete this image?</p>
        </b-modal>
    </div>
</template>
  
<script>
  
import {
BAvatar,BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard,
BModal,VBModal,BCardText, BFormTextarea, BCalendar, BFormDatepicker
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import FullCalendar from '@fullcalendar/vue';

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BCard,
        BBadge,
        BButton,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BModal,
        vSelect,
        BCardText,
        BFormTextarea,
        BCalendar,
        FullCalendar,
        BFormDatepicker
    },

    directives: {
        'b-modal': VBModal,
        Ripple,
    },

    data() {
        return {
            host: 'https://api-biyeruti.smicee.com',

            feedbackBox: false,
            feedbacks: {
                educational_info: {
                    step: 33,
                    section: 'educational_info',
                    feedback: null
                },
                professional_info: {
                    step: 32,
                    section: 'professional_info',
                    feedback: null
                },
                family_info: {
                    step: 40,
                    section: 'family_info',
                    feedback: null
                },
                profile_pictures: {
                    step: 50,
                    section: 'profile_pictures',
                    feedback: null
                },
                nid_info: {
                    step: 60,
                    section: 'nid_info',
                    feedback: null
                }
            },
            
            nameState: null,
            updatePersonalMode:false,
            updateEducationalMode:false,
            updateProfessionalMode:false,
            updateFamilyMode:false,
            updateAddressMode:false,
            updateStatusMode: false,
            popupActive:true,
            img: null,
            status: {
                id: "",
                status: ""
            },
            modify:{
                id: null,
                name: null,
                district: null,
                country: null
            },
            personal: {
                email: "",
                first_name: "",
                last_name: "",
                religion: null,
                blood_group: null,
                phone_number: null,
                date_of_birth: null,
                citizenship: null,
                gender: null,
                height_ft: null,
                height_inch: null,
                marital_status: null,
                profile_status: null,
                premium_status: null,
                phone_verified: null
            },
            educational_info: [],
            education: {
                id: null,
                subject: null,
                passing_year: null,
                institution_name: null,
                country: null
            },
            prof_info:[],
            prof: {
                id: null,
                organization_name: null
            },
            family_info: {
                family_details: null,
                my_bio: null
            },
            present_address: {
                area: null,
                district: null,
                country: null
            },
            permanent_address: {
                district: null,
                country: null
            },
            display_image: null,
            showImageDialog: false,
            country_id:null,
            pageLength: 5,
            dir: false,
            showAdd:true,
            showEdit:true,
            showDelete:true,
            row: {},
            statuses: [ 
                {
                    name: 'Incompatible',
                    value: 'incompatible'
                },
                {
                    name: 'Delete',
                    value: 'deleted'
                }
            ],
            prem_statuses: [ 
                {
                    name: 'Premium',
                    value: 'premium'
                },
                {
                    name: 'Regular',
                    value: 'regular'
                }
            ],
            verified_statuses: [ 
                {
                    name: 'Yes',
                    value: true
                },
                {
                    name: 'No',
                    value: false
                }
            ],
            religion_choice: [
                {
                    name: 'Islam', 
                    value: 'islam'
                },
                {
                    name: 'Hinduism', 
                    value: 'hindu'
                },
                {
                    name: 'Buddhism', 
                    value: 'buddhism'
                },
                {
                    name: 'Christianity', 
                    value: 'christianity'
                },
                {
                    name: 'Others', 
                    value: 'others'
                }  
            ],
            gender_choice: [
                {
                    name: 'Male', 
                    value: 'male'
                },
                {
                    name: 'Female', 
                    value: 'female'
                }
            ],
            marital_status_choice : [
                {
                    name: 'Never Married', 
                    value: 'never_married'
                },
                {
                    name: 'Divorced', 
                    value: 'divorced'
                },
                {
                    name: 'Widow', 
                    value: 'widow'
                },
                {
                    name: 'Widower', 
                    value: 'widower'
                } 
            ],
            feedback:null,
            page: 7,
            pages: [
                {
                    name: 'Phone Number Verification',
                    value: 1
                },
                {
                    name: 'Education & Profession',
                    value: 2
                },
                {
                    name: 'Present & Permanent Address',
                    value: 3
                },
                {
                    name: 'Family Information',
                    value: 4
                },
                {
                    name: 'Profile Pictures',
                    value: 5
                },
                {
                    name: 'NID / Passport',
                    value: 6
                },
                {
                    name: 'Nothing to update',
                    value: 8
                }
            ]
        }
    },

    computed: {
        ...mapState('users', ["details","userError","userLoading"]),
        ...mapState('users', ["admin_feedbacks","userError","userLoading"]),
        ...mapState('country', ["country","countryError","countryLoading"]),
        ...mapState('district', ["district","districtError","districtLoading"]),
        ...mapState('area', ["area","areaError","areaLoading", "submitError","submitSuccess","submitLoading"]),
        ...mapState('degree', ["degree","degreeError","degreeLoading", "submitError","submitSuccess","submitLoading"]),
    },

    watch: {
        details(newValue, oldValue) {
            this.row = this.details
        },

        admin_feedbacks(newValue, oldValue) {
            if(this.row.personal_info.profile_status == 'review' || this.row.personal_info.profile_status == 'further_correction_due') {
                this.feedbackBox = true;
                this.feedbacks = this.admin_feedbacks
            }
        }
    },

    created() { 
        this.$store.dispatch('users/fetchUserDetails', this.$route.params.id).then((result)=>{
            this.row = this.details
            if(this.row.personal_info.profile_status == 'review' || this.row.personal_info.profile_status == 'further_correction_due') {
                this.feedbackBox = true;
                this.feedbacks = this.admin_feedbacks
            }
        })

        this.$store.dispatch('users/fetchAdminFeedbacks', this.$route.params.id).then((result)=>{
            if(this.row.personal_info.profile_status == 'review' || this.row.personal_info.profile_status == 'further_correction_due') {
                this.feedbackBox = true;
                this.feedbacks = this.admin_feedbacks
            }
        })
        
        let permissions = JSON.parse(localStorage.getItem('permissions'))

        this.$store.dispatch('country/fetchCountry')
        this.$store.dispatch('district/fetchDistrict')
        this.$store.dispatch('area/fetchArea')
        this.$store.dispatch('degree/fetchDegree')
    },

    methods: {
        showImage(img) {
            this.display_image = img
            this.showImageDialog = true
        },

        cancelDialog() {
            this.display_image = null
            this.showImageDialog = false
        },

        deleteImage(img_id) {
            this.img = img_id
        },

        deleteModalImage() {
            this.$store.dispatch('users/deleteProfilePictures', {pid:this.$route.params.id, img:this.img}).then(result=>{
                if(result.code==200) {
                    this.updateDeleteMode = false
                    this.$store.dispatch('users/fetchUserDetails', this.$route.params.id)
                }
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: { 
                        title: result.code!=200?`Error`:`Success`,
                        icon: result.code!=200?`ThumbsDownIcon`:'ThumbsUpIcon',
                        variant: result.code!=200?`danger`:'success',
                        text: result.code!=200?`Couldn't delete picture`:'Picture Deleted',
                    },
                })
            })
        },

        updateStatustoChecked() {
            this.$store.dispatch('users/updateAccountStatus', {pid:this.$route.params.id, status:'checked', page:this.page}).then(result=>{
                if(result.code==200) {
                    this.$store.dispatch('users/fetchUserDetails', this.$route.params.id)
                }
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: { 
                        title: result.code!=200?`Error`:`Success`,
                        icon: result.code!=200?`ThumbsDownIcon`:'ThumbsUpIcon',
                        variant: result.code!=200?`danger`:'success',
                        text: result.code!=200?`Couldn't update Status`:'Status Updated',
                    },
                })
            })

            // this.$store.dispatch('users/addAdminFeedback', {feedback:this.feedback, pid:this.$route.params.id}).then(result=>{
            //     if(result.code==200) {
            //         this.$store.dispatch('users/fetchUserDetails', this.$route.params.id)
            //         this.feedback = null
            //     }
            // })
        },

        updateStatustoFurtherCorrectionDue(){
            this.feedbackBox = true;

            this.feedbacks = {
                educational_info: {
                    step: 33,
                    section: 'educational_info',
                    feedback: this.feedbacks.educational_info && this.feedbacks.educational_info.feedback ? this.feedbacks.educational_info.feedback : null,
                    resolved: this.feedbacks.educational_info && this.feedbacks.educational_info.resolved ? true : false
                },
                professional_info: {
                    step: 32,
                    section: 'professional_info',
                    feedback: this.feedbacks.professional_info && this.feedbacks.professional_info.feedback ? this.feedbacks.professional_info.feedback : null,
                    resolved: this.feedbacks.professional_info && this.feedbacks.professional_info.resolved ? true : false
                },
                family_info: {
                    step: 40,
                    section: 'family_info',
                    feedback: this.feedbacks.family_info && this.feedbacks.family_info.feedback ? this.feedbacks.family_info.feedback : null,
                    resolved: this.feedbacks.family_info && this.feedbacks.family_info.resolved ? true : false
                },
                profile_pictures: {
                    step: 50,
                    section: 'profile_pictures',
                    feedback: this.feedbacks.profile_pictures && this.feedbacks.profile_pictures.feedback ? this.feedbacks.profile_pictures.feedback : null,
                    resolved: this.feedbacks.profile_pictures && this.feedbacks.profile_pictures.resolved ? true : false
                },
                nid_info: {
                    step: 60,
                    section: 'nid_info',
                    feedback: this.feedbacks.nid_info && this.feedbacks.nid_info.feedback ? this.feedbacks.nid_info.feedback : null,
                    resolved: this.feedbacks.nid_info && this.feedbacks.nid_info.resolved ? true : false
                }
            }
            // console.log('working')
            // console.log(this.feedbackBox)
            // this.updateStatusMode = true; 
        },

        submitAllFeedbacks() {
            this.$store.dispatch('users/updateAccountStatus', {pid:this.$route.params.id, status:'further_correction_due', page: 7}).then(result=>{
                if(result.code == 200) {
                    this.$store.dispatch('users/submitFeedbacks', {pid: this.$route.params.id, feedbacks: this.feedbacks}).then(result1 => {
                        if(result1.code == 200) {
                            this.$store.dispatch('users/fetchUserDetails', this.$route.params.id)
                            this.$store.dispatch('users/fetchAdminFeedbacks', this.$route.params.id)
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: { 
                                    title: result1.code!=200?`Error`:`Success`,
                                    icon: result1.code!=200?`ThumbsDownIcon`:'ThumbsUpIcon',
                                    variant: result1.code!=200?`danger`:'success',
                                    text: result1.code!=200?`Couldn't send feedback`:'Feedback sent',
                                },
                            })
                        }
                    }) 
                }
            })
        },

        feedbackResolved(section) {
            this.$store.dispatch('users/toggleFeedbackResolved', {pid:this.$route.params.id, section:section}).then(result=>{
                if(result.code == 200) {
                    this.$store.dispatch('users/fetchUserDetails', this.$route.params.id)
                    this.$store.dispatch('users/fetchAdminFeedbacks', this.$route.params.id)
                    
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: { 
                            title: result.code!=200?`Error`:`Success`,
                            icon: result.code!=200?`ThumbsDownIcon`:'ThumbsUpIcon',
                            variant: result.code!=200?`danger`:'success',
                            text: result.code!=200?`Couldn't update resolved status`:'Feedback marked as resolved successfully'
                        }
                    })
                }
            })
        },

        handleStatus() {
            this.$store.dispatch('users/updateAccountStatus', {pid:this.$route.params.id, status:'further_correction_due', page:this.page}).then(result=>{
                if(result.code==200) {
                    this.updateStatusMode = false
                    this.$store.dispatch('users/fetchUserDetails', this.$route.params.id)
                }
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: { 
                        title: result.code!=200?`Error`:`Success`,
                        icon: result.code!=200?`ThumbsDownIcon`:'ThumbsUpIcon',
                        variant: result.code!=200?`danger`:'success',
                        text: result.code!=200?`Couldn't update Status`:'Status Updated',
                    },
                })
            })

            this.$store.dispatch('users/addAdminFeedback', {feedback:this.feedback, pid:this.$route.params.id}).then(result=>{
                if(result.code==200) {
                    this.updateStatusMode = false
                    this.$store.dispatch('users/fetchUserDetails', this.$route.params.id)
                    this.feedback = null
                }
            })
        },

        editPersonal(data) {
            this.updatePersonalMode = true;
            this.updateEducationalMode = false;
            this.updateProfessionalMode = false;
            this.updateAddressMode = false;
            this.updateFamilyMode = false;
            // console.log('dgdfgfb --->', data)
            this.personal.email = data.user_object.email,
            this.personal.first_name = data.user_object.first_name,
            this.personal.last_name = data.user_object.last_name,
            this.personal.religion = data.personal_info.religion,
            // this.personal.blood_group = data.personal_info.blood_group,
            this.personal.phone_number = data.personal_info.phone_number,
            this.personal.date_of_birth = data.personal_info.date_of_birth,
            this.personal.citizenship = data.personal_info.citizenship.id,
            // this.personal.hometown = data.personal_info.hometown,
            this.personal.gender = data.personal_info.gender,
            this.personal.height_ft = data.personal_info.height_ft,
            this.personal.height_inch = data.personal_info.height_inch,
            this.personal.marital_status = data.personal_info.marital_status
            // this.personal.profile_status = data.personal_info.profile_status,
            // this.personal.premium_status = data.personal_info.premium_status,
            // this.personal.phone_verified = data.personal_info.phone_verified
        },

        editEducational(education) {
            this.updateEducationalMode = true;
            this.updatePersonalMode = false;
            this.updateProfessionalMode = false;
            this.updateAddressMode = false;
            this.updateFamilyMode = false;
            this.educational_info = education
        },

        editFamily(family) {
            this.updateFamilyMode = true;
            this.updateEducationalMode = false;
            this.updatePersonalMode = false;
            this.updateAddressMode = false;
            this.updateProfessionalMode = false;
            this.family_info = family
        },

        editAddress(address) {
            this.updateAddressMode = true;
            this.updateFamilyMode = false;
            this.updateEducationalMode = false;
            this.updatePersonalMode = false;
            this.updateProfessionalMode = false;
            this.present_address.area = address.present_address.area_id
            this.present_address.district = address.present_address.district.id
            this.present_address.country = address.present_address.country.id
            this.permanent_address.district = address.permanent_address.district.id
            this.permanent_address.country = address.permanent_address.country.id
        },

        editProfessional(profession) {
            this.updateProfessionalMode = true;
            this.updateEducationalMode = false;
            this.updatePersonalMode = false;
            this.updateFamilyMode = false;
            this.prof_info = profession
        },

        editEducation(educationInfo) {
            this.education.id = educationInfo.id
            this.education.subject = educationInfo.subject
            this.education.institution_name = educationInfo.institution_name
            this.education.passing_year = educationInfo.passing_year
            this.education.country = educationInfo.country_id

            this.$store.dispatch('users/updateEducationalInfo', {education:this.education, pid:this.$route.params.id}).then(result=>{
                if(result.code==200) {
                    // this.updateEducationalMode = false
                    this.$store.dispatch('users/fetchUserDetails', this.$route.params.id)
                }
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: { 
                        title: result.code!=200?`Error`:`Success`,
                        icon: result.code!=200?`ThumbsDownIcon`:'ThumbsUpIcon',
                        variant: result.code!=200?`danger`:'success',
                        text: result.code!=200?`Couldn't update educational info`:'Educational Information Updated',
                    },
                })
            })
        },

        editProfession(profInfo) {
            this.prof.id = profInfo.id
            this.prof.organization_name = profInfo.organization_name

            this.$store.dispatch('users/updateProfessionalInfo', {prof:this.prof, pid:this.$route.params.id}).then(result=>{
                if(result.code==200) {
                    // this.updateEducationalMode = false
                    this.$store.dispatch('users/fetchUserDetails', this.$route.params.id)
                }
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: { 
                        title: result.code!=200?`Error`:`Success`,
                        icon: result.code!=200?`ThumbsDownIcon`:'ThumbsUpIcon',
                        variant: result.code!=200?`danger`:'success',
                        text: result.code!=200?`Couldn't update professional info`:'Professional Information Updated',
                    },
                })
            })
        },

        editFamilyInformation() {
            this.$store.dispatch('users/updateFamilyInfo', {family:this.family_info, pid:this.$route.params.id}).then(result=>{
                if(result.code==200) {
                    this.updateFamilyMode = false
                    this.$store.dispatch('users/fetchUserDetails', this.$route.params.id)
                }
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: { 
                        title: result.code!=200?`Error`:`Success`,
                        icon: result.code!=200?`ThumbsDownIcon`:'ThumbsUpIcon',
                        variant: result.code!=200?`danger`:'success',
                        text: result.code!=200?`Couldn't update family info`:'Family Information Updated',
                    },
                })
            })
        },

        editPresentAddress(){
            this.$store.dispatch('users/updatePresentAddress', {address:this.present_address, pid:this.$route.params.id}).then(result=>{
                if(result.code==200) {
                    // this.updateFamilyMode = false
                    this.$store.dispatch('users/fetchUserDetails', this.$route.params.id)
                }
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: { 
                        title: result.code!=200?`Error`:`Success`,
                        icon: result.code!=200?`ThumbsDownIcon`:'ThumbsUpIcon',
                        variant: result.code!=200?`danger`:'success',
                        text: result.code!=200?`Couldn't update present address info`:'Present Address Information Updated',
                    },
                })
            })
        },

        editPermanentAddress(){
            this.$store.dispatch('users/updatePermanentAddress', {address:this.permanent_address, pid:this.$route.params.id}).then(result=>{
                if(result.code==200) {
                    // this.updateFamilyMode = false
                    this.$store.dispatch('users/fetchUserDetails', this.$route.params.id)
                }
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: { 
                        title: result.code!=200?`Error`:`Success`,
                        icon: result.code!=200?`ThumbsDownIcon`:'ThumbsUpIcon',
                        variant: result.code!=200?`danger`:'success',
                        text: result.code!=200?`Couldn't update hometown info`:'Hometown Information Updated',
                    },
                })
            })
        },

        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            return valid
        },

        resetModal() {
            this.nameState = null
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        },

        handleEducationOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleEducationSubmit()
        },

        async handleSubmit() {
            if (!this.checkFormValidity()) {
                return
            }
            
            await this.$store.dispatch('users/updatePersonalInformations', {user:this.personal, pid:this.$route.params.id}).then(result=> {
                if(result.code == 200) {
                    this.$toast({
                        component: ToastificationContent, 
                        position: 'top-right',
                        props: {
                            title: `Success`,
                            icon: 'ThumbsUpIcon',
                            variant: 'success',
                            text: result.response,
                        },
                    })

                    this.updatePersonalMode = false
                } else {
                    this.$toast({
                        component: ToastificationContent, 
                        position: 'top-right',
                        props: {
                            title: `Error`,
                            icon: 'ThumbsDownIcon',
                            variant: 'error',
                            text: result.response,
                        },
                    })
                }
            })

            this.$nextTick(() => {
                this.$refs['my-modal'].toggle('#toggle-btn')
            })
        },

        async handleEducationSubmit() {
            if (!this.checkFormValidity()) {
                return
            }

            // console.log(this.educational_info)
            
            // await this.$store.dispatch('users/updatePersonalInformations', {user:this.personal, pid:this.$route.params.id}).then(result=> {
            //     if(result.code == 200) {
            //         this.$toast({
            //             component: ToastificationContent, 
            //             position: 'top-right',
            //             props: {
            //                 title: `Success`,
            //                 icon: 'ThumbsUpIcon',
            //                 variant: 'success',
            //                 text: result.response,
            //             },
            //         })

            //         this.updatePersonalMode = false
            //     } else {
            //         this.$toast({
            //             component: ToastificationContent, 
            //             position: 'top-right',
            //             props: {
            //                 title: `Error`,
            //                 icon: 'ThumbsDownIcon',
            //                 variant: 'error',
            //                 text: result.response,
            //             },
            //         })
            //     }
            // })

            this.$nextTick(() => {
                this.$refs['my-modal'].toggle('#toggle-btn')
            })
        }
    }
}
</script>
  
<style scoped>
.profile-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 4rem;
}

.nid-container {
    width: 25%;
}

.nid-passport-image {
    width: 100%;
    height: 270px;
    background-size: cover;
    object-fit: cover;
}

.no-scroll-part {
    width: 20%;
    display: flex;
    flex-direction: column;
    height: fit-content;
    position: fixed;
    gap: 1rem;
}

.info-container {
    width: 75%;
}

.user-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.user-width {
    width: 50%;
}

.table-row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.title-row {
    display: flex;
    justify-content: space-between;
}

.title {
    color: rgb(75 85 99);
    font-size: 1.5rem;
    font-weight: 500;
    color: #a41c1c;
}

.title-edit {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
}

.capital-letter {
    text-transform: capitalize;
}

.top-gap {
    margin-top: 20px;
}

.bottom-gap {
    margin-top: 24px;
}

.education-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
}

.picture-display {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 1rem;
}

.image-size {
    height: 180px;
}

.optional-image-container {
    display: flex;
    gap: 18px;
}

.modal-container {
   display: flex;
   flex-direction: column;
   align-items: center; 
   justify-content: center; 
   height: 100vh;
}

.search-overlay {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    background-color: #000000d3;
}

.img-size {
    height: 380px;
}

.cancel-image-button {
    height: 35px;
    width: 35px;
    cursor: pointer;
    padding: 4px;
}

.cancel-button-position {
    display: flex;
    justify-content: end; 
}

.address-title {
    color: #7a3030;
    font-size: medium;
    font-weight: 500;
}

.title-position {
    width: 150px;
}

.title-position-small {
    width: 100px;
}

.image-container {
    position: relative;
    display: flex;
}

.button-position {
    position: absolute;
}

.cross-button {
    cursor: pointer;
    margin-left: 154px;
    margin-top: 6px;
    height: 20px;
    background-color: white;
    padding: 4px;
}

.view-log-button {
    font-size: medium;
    background-color: #b13032;
    color: white;
    padding: 4px;
    border-radius: 0.25rem;
    cursor: pointer;
}

.button-container {
    width: 100%;
    display: flex;
    gap: 1rem;
    justify-content: end;
    align-items: end;
    margin-bottom: 40px;
}

.button-half {
    display: flex;
    gap: 1rem;
}

@media screen and (max-width: 1536px) {
    .nid-container {
        width: 35%;
    }

    .info-container {
        width: 65%;
    }

    .no-scroll-part {
        width: 25%;
        display: flex;
        flex-direction: column;
        height: fit-content;
        position: fixed;
        gap: 1rem;
    }
}

@media screen and (max-width: 1500px) {
    /* .nid-passport-image {
        max-width: 320px !important;
    } */

    .picture-display {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1rem;
    }

    .image-size {
        /* width: 220px; */
        height: 220px;
    }

    .cross-button {
        cursor: pointer;
        margin-left: 200px;
        margin-top: 6px;
        height: 20px;
        background-color: white;
        padding: 4px;
    }

    .education-container {
        margin-left: 20px;
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 1rem;
    }

    .user-container {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        gap: 0rem;
    }

    .user-width {
        width: 100%;
    }
}

@media screen and (max-width: 1280px) {
    .button-half {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

@media screen and (max-width: 1199px) {
    .no-scroll-part {
        width: 31%;
        display: flex;
        flex-direction: column;
        height: fit-content;
        position: fixed;
        gap: 1rem;
    }
}

@media screen and (max-width: 1050px) {
    .profile-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 4rem;
    }

    .nid-container {
        width: 100%;
    }

    .info-container {
        width: 100%;
    }

    .no-scroll-part {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 2rem;
        position: relative;
    }

    .button-half {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }
}
</style>